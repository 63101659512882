import revive_payload_client_jjapnvNbTJ from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9PdSKFnJWx from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mmIuBao5X6 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_72Ln91NeKg from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_zboxvbfzjphqpid7lxncouqabe/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_1hZT0xmX2F from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jHQBH4kitG from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hjjlkWFH7z from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cpJTOgnBa3 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_J5Oz4FfTjw from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_gx366rzhrwutnqnyxldhzp5gai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_CIHydO3C86 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_t_j4v4zlttadz4boppdp4r2zkuku/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_qknhMqbCCd from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_t_j4v4zlttadz4boppdp4r2zkuku/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_l60Qcvuqtl from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_t_j4v4zlttadz4boppdp4r2zkuku/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_U8uu9iayPn from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_G9uUzAAAic from "/opt/build/repo/nuxt/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.20_magicast@0.3.5_rollup@4.29.1_webpack@5.97.1_esbuild@0.24.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import plugin_client_nypiWNZhqg from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import components_client_SBRONtKJ0S from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-swiper@2.0.0_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_DNVPkuEhzA from "/opt/build/repo/nuxt/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_ters_aldd7dztmfqfa2c5sunjfuz2ca/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_3I2p9n4A7D from "/opt/build/repo/nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.29.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import defaults_VZiKsgzm94 from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.3_jiti@2.4.2_t_j4v4zlttadz4boppdp4r2zkuku/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_jjapnvNbTJ,
  unhead_9PdSKFnJWx,
  router_mmIuBao5X6,
  _0_siteConfig_72Ln91NeKg,
  payload_client_1hZT0xmX2F,
  navigation_repaint_client_jHQBH4kitG,
  check_outdated_build_client_hjjlkWFH7z,
  chunk_reload_client_cpJTOgnBa3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J5Oz4FfTjw,
  siteConfig_CIHydO3C86,
  inferSeoMetaPlugin_qknhMqbCCd,
  titles_l60Qcvuqtl,
  plugin_U8uu9iayPn,
  plugin_G9uUzAAAic,
  plugin_client_nypiWNZhqg,
  components_client_SBRONtKJ0S,
  plugin_DNVPkuEhzA,
  plugin_3I2p9n4A7D,
  defaults_VZiKsgzm94
]