<template>
  <div>
    <NuxtLoadingIndicator color="#111" />

    <div class="flex min-h-screen flex-col">
      <div class="sticky top-0 z-20 bg-white">
        <SiteHeader />
      </div>

      <SiteSearch id="site-search" />

      <main class="flex flex-1 flex-col">
        <NuxtPage class="flex-1" />
      </main>

      <SiteFooter />

      <CookieControl locale="en" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SiteSettings } from "~/@types/siteSettings"
import { groqSettings } from "~/groq/settings"
import favicons from "~/config/favicons.json"

const sanity = useSanity()

const { data: settings } = await useAsyncData<SiteSettings>(
  "site-settings",
  () => sanity.fetch(groqSettings()),
)

useHead({
  htmlAttrs: {
    lang: "en",
  },
  link: [...favicons],
})

useSeoMeta({
  description: settings.value?.siteDescription,
  fbAppId: settings.value?.facebookAppId,
})

defineOgImage({
  component: "root",
  url: settings.value?.siteImage,
  width: 1200,
  height: 600,
  alt: "CALIBRE",
})
</script>
